export const ENV = 'staging'
export const MEDIA_CDN = 'https://master-media.productwind.com/' as string
export const APPSYNC_CONFIG = {
  aws_user_pools_id: 'us-east-2_CnFrukvpn',
  aws_user_pools_web_client_id: '75ui7jqh0iacot4et8cunrer9e',
  aws_appsync_graphqlEndpoint: 'https://z4pujxypvzhf7arr7l7x3qcqvm.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_apiKey: 'da2-nem63xtjcvdljhh6rbqxhell5m'
}
export const UNLEASH_CLIENT_ID =
  'momentum:development.8c1aa15e406d3f68e38ff3ab347f9a3c0d9eb3055784128615766add' as string
export const MIXPANEL_TOKEN = 'a405f77beaef2481cdab6eb525101f89'
